/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M6 13a3 3 0 01-5.995.176L0 13v-3a3 3 0 012.824-2.995L3 7h4v4h.189l.007-.025a5.57 5.57 0 014.41-3.898l.247-.036L11.133 2H8V0h3.133a2 2 0 011.952 1.564l.028.153L14.153 9h-1.622a3.566 3.566 0 00-3.51 2.935l-.029.189-.11.876H6zm7-3a3 3 0 110 6 3 3 0 010-6zm-9 3H2a1 1 0 001.993.117L4 13zm9-1a1 1 0 100 2 1 1 0 000-2zM5 9H3a1 1 0 00-.993.883L2 10v1h3V9zm2-5v2H2a2 2 0 012-2h3z',
  ],

  24: [
    'M18.5 15a3.5 3.5 0 110 7 3.5 3.5 0 010-7zM17.18 2a2 2 0 011.924 1.454l.037.154L21.22 14h-3.097a4.28 4.28 0 00-4.097 3.04l-.056.203-.19.757H10v.4c0 1.988-1.567 3.6-3.5 3.6-1.869 0-3.395-1.506-3.495-3.402L3 18.4V18H2v-4a4 4 0 013.8-3.995L6 10h5v6h1.271l.079-.193a6.282 6.282 0 015.52-3.802l.253-.005h.657l-1.6-8H14V2h3.18zm1.32 15a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM8 18H5v.4c0 .846.61 1.521 1.365 1.594L6.5 20c.77 0 1.424-.628 1.494-1.453L8 18.4V18zm1-6H6a2 2 0 00-1.995 1.85L4 14v2h5v-4zm2-5v2H5a2 2 0 012-2h4z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconMobileVenue = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconMobileVenue.displayName = 'IconMobileVenue';
