/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M12.5 6C14.433 6 16 7.492 16 9.333c0 2.5-3.5 6.667-3.5 6.667l-.284-.352C11.382 14.593 9 11.396 9 9.333 9 7.493 10.567 6 12.5 6zM8 1l3.407 2.858.423.356-1.285 1.532-1.728-1.45L8 3.612 4 6.967V13h5v2H4a2 2 0 01-2-2V8.644l-.375.316L.34 7.428 8 1zm4.5 7c-.85 0-1.5.62-1.5 1.333 0 .307.166.86.497 1.55.195.405.436.84.716 1.293l.217.343.07.106.07-.106c.298-.462.56-.907.778-1.327l.155-.31c.331-.689.497-1.242.497-1.549C14 8.62 13.35 8 12.5 8z',
  ],

  24: [
    'M22.036 10.393a5.951 5.951 0 01.662 7.628L18.5 24l-4.198-5.979a5.951 5.951 0 01.662-7.628 5 5 0 017.072 0zm-5.657 1.415a3.951 3.951 0 00-.55 4.9l.11.164 2.561 3.647 2.561-3.647a3.951 3.951 0 00-.303-4.921l-.137-.143a3 3 0 00-4.242 0zM9.5 0l7.66 6.428-1.285 1.532L9.5 2.611 3.999 7.226 4 17h1.999L6 10.997c0-.55.451-.997.99-.997h4.02c.546 0 .99.453.99.997V19H4a2 2 0 01-2-2l-.001-8.096-.712.599L0 7.97 9.5 0zm.5 12H8v5h2v-5zm8.5 1.5a1 1 0 110 2 1 1 0 010-2z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconHomebasedVenue = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconHomebasedVenue.displayName = 'IconHomebasedVenue';
